<template>
    <div id="Foreign">
      <div class="box-card">
        <!-- <div class="more" > -->
            <!-- <a href="https://finance.sina.com.cn/money/forex/hq/DINIW.shtml" target="_blank">查看行情</a></div> -->
        <div style="margin-top: 15px; height:70vh;width: 100%;" >
            <iframe src="https://futures.cngold.org/hangqing/" style="width: 100%;height: 100%;"></iframe>
          </div>
      </div>
    </div>
  </template>
  
  <script></script>
  <style scoped lang="less">
  #Foreign{
    /* margin-top: 20px;
    height: 921px; */
  }
  .box-card{
    height: 100%;
  }
  .more{
      float: right;
      box-sizing: border-box;
      font-size: 14px;
      font-family: PingFangSC-Regular, PingFang SC;
      font-weight: 400;
      color: #3B6EFB;
      line-height: 26px;
      width: 68px;
      height: 28px;
      text-align: center;
      background: #F9FAFF;
      border-radius: 4px;
      border: 1px solid #3B6EFB;
      cursor: pointer;
      margin-bottom:15px;
      a{
        color: #3B6EFB;
      }
    }
  </style>